/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { TemplateAnnotations, PageEditorBridge } from '@magnolia/template-annotations';
import {
  EditorProvider, ComponentHelper, constants, EditorContextHelper, PersonalizationService
} from '../../util';
import { EditableComment } from '../EditableComment';

class EditablePage extends React.PureComponent {
  static propTypes = {

    children: PropTypes.elementType,
    // eslint-disable-next-line react/require-default-props
    content: PropTypes.object.isRequired,
    templateDefinitions: PropTypes.object,
    templateAnnotations: PropTypes.object,
    config: PropTypes.shape({
      componentMappings: PropTypes.object,
      fallbackComponent: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
    })
  }

  static defaultProps = {
    children: null,
    // eslint-disable-next-line react/default-props-match-prop-types
    content: null,
    templateDefinitions: null,
    templateAnnotations: null,
    config: {
      componentMappings: {},
      fallbackComponent: false
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedComponentVariants: null,
      listeners: {}
    };
  }

  componentDidMount() {
    if (EditorContextHelper.inIframe()) {
      PageEditorBridge.onMessage(this.state.listeners, 'updateState', (message) => {
        this.setState({
         selectedComponentVariants: message.selectedComponentVariants
        });
      });
      PageEditorBridge.init(this.state.listeners);
    }
  }

  onReady() {
    EditorContextHelper.onFrameReady();
    EditorContextHelper.refresh();
  }

  getAnnotationString() {
    const { content, templateDefinitions, templateAnnotations } = this.props;
    if (!content) {
      return '';
    }
    if (templateDefinitions && templateDefinitions[content?.[constants.TEMPLATE_ID_PROP]]) {
      // eslint-disable-next-line no-console
      console.warn('Template-definitions endpoint is deprecated. Please migrate your code to use template-annotations endpoint instead.');
      return TemplateAnnotations.getPageCommentString(content, templateDefinitions[content?.[constants.TEMPLATE_ID_PROP]]);
    }
    if (templateAnnotations && templateAnnotations[content?.['@path']]) {
      return templateAnnotations[content?.['@path']];
    }
    return '';
  }

  getContextValue() {
    const {
      templateDefinitions, content, config
    } = this.props;
    const wrappedTemplateAnnotations = this.getTemplateAnnotations();
    const { componentMappings, fallbackComponent } = config;
    const isDevMode = process.env.NODE_ENV === 'development';
    return {
      templateDefinitions,
      templateAnnotations: wrappedTemplateAnnotations,
      componentMappings,
      fallbackComponent,
      content,
      isDevMode
    };
  }

  getTemplateAnnotations() {
    if (!this.props.templateAnnotations || !this.state.selectedComponentVariants) {
      return this.props.templateAnnotations;
    }
    return PersonalizationService.wrap(this.props.templateAnnotations, this.state.selectedComponentVariants);
  }

  render() {
    const { children } = this.props;
    const context = this.getContextValue();
    const { content, templateAnnotations, componentMappings, fallbackComponent } = context;
    const wrappedContent = TemplateAnnotations.generateMissingAreas(content, templateAnnotations);
    const renderingContent = templateAnnotations ? PersonalizationService.getVariant(wrappedContent, templateAnnotations) : wrappedContent;
    const pageComponent = children || ComponentHelper.getRenderedComponent(renderingContent, componentMappings, fallbackComponent);
    // NOTE: We need a div tag as a parent node for Page's child HTML. It will cause an issue if we
    // don't have a parent node.
    return (
      <EditorProvider value={context}>
        <EditableComment annotation={this.getAnnotationString()} callback={this.onReady} alwaysRender />
        <div key={ComponentHelper.buildKey(content)}>
          {pageComponent}
        </div>
      </EditorProvider>
    );
  }
}

export default EditablePage;
