// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import constants from './constants';

function DefaultFallbackComponent(props) {
  const { metadata, data } = props;
  const propItems = Object.entries(data).map(([key, value]) => (
    <div key={key}>
      {key}: {formatValue(value)}
    </div>
  ));
  return (
    <div>
      <div>
        <strong>
          <em>Template not found: { getTemplateId() }</em>
        </strong>
      </div>
      {propItems}
      <p />
    </div>
  );

  function trimIfObject(value) {
    if (value === '[object Object]') {
      return '[object]';
    }
    return value;
  }

  function formatValue(value) {
    return trimIfObject(` ${value}`);
  }

  function getTemplateId() {
    return metadata[constants.TEMPLATE_ID_PROP];
  }
}

DefaultFallbackComponent.propTypes = {
  metadata: PropTypes.object,
  data: PropTypes.object
};
DefaultFallbackComponent.defaultProps = {
  metadata: {},
  data: {}
};

export default DefaultFallbackComponent;
